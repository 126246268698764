#simulator-vaccination {
    display: flex;
    flex-direction: column;
    align-items: center;
    /*background: hsl(45deg 100% 51% / 20%);*/
    box-shadow: rgb(52 58 64 / 20%) 1px 2px 20px;
    padding: 4rem !important;
    max-width: 50%;
    position: relative;
}



#simulator-vaccination input[type="date"] {
    width: 300px;
}

#simulator-vaccination h2 + p {
    margin-top: 8px !important;
    margin-bottom: 32PX;
}


#simulator-vaccination h2 {
    font-weight: bold;
    font-size: 1.8rem;
    line-height: normal;
    text-align: center;
}


#result {
    color: hsl(188deg 78% 30%);
    font-size: 1.2rem;
    text-align: center;
    width: 100%;
    margin: 16px auto !important;
}

#simulator-vaccination form > div:not(:first-child) {
    margin-bottom: 16px !important;
}

#simulator-vaccination p {
    text-align: center;
}

#simulator-vaccination label {
    font-weight: bold;
}

#simulator-vaccination .block-input label {
    font-weight: normal;
}

#date-last-injection {
    margin: 32px 0 !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.green-result strong, .red-result strong {
    display: block;
    font-size: 1.8rem;
    margin: 16px !important;
    line-height: normal;
}

#result.green-result {
    color: hsl(134deg 61% 31%);
    background: hsl(134deg 61% 41% / 22%);
    padding: 2rem !important;
    border-radius: 5px;
}

.green-result strong, .green-result .horloge {
    color: hsl(134deg 61% 31%) !important;
}
.red-result strong, .red-result .horloge {
    color: hsl(0deg 61% 31%) !important;
}
#result.red-result {
    color: hsl(0deg 61% 31%);
    background: hsl(0deg 61% 41% / 22%);
    padding: 2rem !important;
}

#immoBox a {
    color: blue;
}


@media all and (max-width: 1024px) {
    #simulator-vaccination h2 + p {
        text-align: left;
    }
    #simulator-vaccination {
        padding: 3rem !important;
        margin: 0 auto !important;
        max-width: 100%;
    }

}

@media all and (min-width: 768px) {
    #simulator-vaccination {
        margin: 100px auto 0 auto!important;
    }
}

#startBtn {
    background: #3f51b5;
    border: none;
    font-size: 1.2rem;
    color: white;
    margin: 0 !important;
    cursor: pointer;
    position: absolute;
    z-index: 9999;
    bottom: 0;
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
#ageBox, #immoBox, #covidPatientBox, #result, #injectionBox, #horloge {
    display: none;
}

.formBox {
    display: none;
}

.revealBox {
    display: block !important;
}

.revealBoxFlex {
    display: flex !important;
}

.finalPosition {
    display: block !important;
}
.finalPositionBtn {
    display: none !important;
}
